import './App.css';
import Hero from './components/Hero/hero'
import Program from './components/Program/program';
import Reason from './components/Reasons/reason'
import Plans from './components/plans/plans';
import Testimonial from './components/Testimonials/testimonial';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/> 
          <Reason/> 
          <Plans/>
          <Testimonial/>
          <Join/>
          <Footer/>
          
    </div>
  );
}

export default App;
